import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import { slice as mailSlice } from './slices/mail';
import { slice as chatSlice } from './slices/chat';
import { slice as productSlice } from './slices/product';
import { slice as calendarSlice } from './slices/calendar';
import { slice as kanbanSlice } from './slices/kanban';
import { slice as pfProductSlice } from './slices/pf_product';
import { slice as studioSlice } from './slices/studio';
import { authSlice } from './slices/auth';
import { printful } from './slices/printful';

// ----------------------------------------------------------------------

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  [mailSlice.name]: mailSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [calendarSlice.name]: calendarSlice.reducer,
  [kanbanSlice.name]: kanbanSlice.reducer,
  [pfProductSlice.name]: pfProductSlice.reducer,
  [studioSlice.name]: studioSlice.reducer,
  [productSlice.name]: productSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [printful.name]: printful.reducer,
});

const rootPersistConfig = {
  key: 'root:airomni_vander',
  storage,
  keyPrefix: '',
  whitelist: [authSlice.name],
  blacklist: [
    mailSlice.name,
    chatSlice.name,
    calendarSlice.name,
    kanbanSlice.name,
    pfProductSlice.name,
    studioSlice.name,
    productSlice.name,
    printful.name,
  ],
};

export { rootPersistConfig, rootReducer };
