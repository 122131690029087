import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    user: null,
  },
  reducers: {
    onAuthenticated(state, action) {
      state.user = action.payload;
    },
    onUnAuthenticated(state, action) {
      state.user = null;
    },
    onPrintFulAuthenticated(state, action) {
      state.user = { ...(state.user || {}), printfulToken: action.payload };
    },
  },
  selectors: {
    authenticatedUserSelector(state) {
      return state.user;
    },
    printFulTokenSelector(state) {
      return state?.user?.printfulToken;
    },
    storeIdSelector(state) {
      return state?.user?.store?.id;
    },
  },
});

export const { authenticatedUserSelector, printFulTokenSelector, storeIdSelector } = authSlice.selectors;
export const { onAuthenticated, onUnAuthenticated, onPrintFulAuthenticated } = authSlice.actions;
